import axios from 'axios'
const axiosInstance = axios.create({
    headers: {
        'Content-Type': 'application/json',
    }
})

const BASE_URL = 'https://api.safetelecom.net/api'
const MESSAGING_URL = 'https://api.safetelecom.net/messaging'

export const clientApi = {
    // Client CRUD operations
    getClient(id) {
        return axiosInstance.get(`${BASE_URL}/clients/${id}`)
    },
    createClient(client) {
        return axiosInstance.post(`${BASE_URL}/clients`, client)
    },
    updateClient(id, client) {
        return axiosInstance.put(`${BASE_URL}/clients/${id}`, client)
    },
    deleteClient(id) {
        return axiosInstance.delete(`${BASE_URL}/clients/${id}`)
    },

    // Reference data
    getApps(accessLevel) {
        return axiosInstance.get(`${BASE_URL}/apps?access=${accessLevel}`)
    },
    getStores(accessLevel) {
        return axiosInstance.get(`${BASE_URL}/stores?access=${accessLevel}`)  // Remove the /phones
    },
    getDevices() {
        return axiosInstance.get(`${BASE_URL}/devices`)
    },
    getRetailers() {
        return axiosInstance.get(`${BASE_URL}/retailers`)
    },
    getAuditors() {
        return axiosInstance.get(`${BASE_URL}/auditors`)
    },
    getCarriers() {
        return axiosInstance.get('https://raw.githubusercontent.com/elisam98/mcc-mnc-table/master/mcc-mnc-table.json')
    },

    // Activity management
    getActivities() {
        return axiosInstance.get(`${BASE_URL}/activities`)
    },
    getActivityGroups() {
        return axiosInstance.get(`${BASE_URL}/activitygroups`)
    },
    getDefaultBlockedActivities() {
        return axiosInstance.get(`${BASE_URL}/activitygroups/60dde9659aefe3003abab569`)
    },
    getStoreProfile(storeId) {
        return axiosInstance.get(`${BASE_URL}/stores/${storeId}`)
    },

    // FCM messaging
    sendFcmMessage(payload) {
        return axiosInstance.post(`${MESSAGING_URL}/send-message`, payload)
    },
    getProjectIds() {
        return axiosInstance.get(`${MESSAGING_URL}/service-accounts`)
    }
}