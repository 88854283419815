<template>
    <v-container>
        <v-card>
            <v-card-title>
                Blocked Activities
                <v-btn dark x-large text color="error" @click="$emit('clear')">Clear</v-btn>
            </v-card-title>
            <v-container>
                <v-row dense>
                    <v-col v-for="activity in activities" :key="activity._id" cols="12" dense>
                        <v-checkbox v-model="selectedActivities" :label="activity.name" :value="activity._id" multiple
                            @change="$emit('input', selectedActivities)"></v-checkbox>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-container>
</template>

<script>
export default {
    name: 'ClientBlockedActivities',

    props: {
        activities: {
            type: Array,
            required: true
        },
        value: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            selectedActivities: []
        }
    },

    watch: {
        value: {
            immediate: true,
            handler(newVal) {
                this.selectedActivities = [...newVal]
            }
        }
    }
}
</script>