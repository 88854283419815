<template>
    <v-card>
        <v-list two-line subheader>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>Device Added</v-list-item-title>
                    <v-list-item-subtitle>{{ moment(client.lastUpdated).format('MMMM DD, YYYY h:mm a')
                        }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>Device Age</v-list-item-title>
                    <v-list-item-subtitle>{{ moment.preciseDiff(Date.now(), client.lastUpdated)
                        }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>Phone Number</v-list-item-title>
                    <v-list-item-subtitle>{{ client.phoneNumber ? new PhoneNumber(client.phoneNumber.toString(),
                        'US').toJSON()['number']['national'] : 'unset' }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>Device (codename)</v-list-item-title>
                    <v-list-item-subtitle>{{ deviceName }} ({{ client.device }})</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>Serial</v-list-item-title>
                    <v-list-item-subtitle>{{ client.serialNo }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>Account Hash</v-list-item-title>
                    <v-list-item-subtitle>{{ client.accountHash }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>IMEI</v-list-item-title>
                    <v-list-item-subtitle>{{ client.imei }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>Baseband</v-list-item-title>
                    <v-list-item-subtitle>{{ client.baseband }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>Carrier (SIM)</v-list-item-title>
                    <v-list-item-subtitle>{{ client.carrier }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="carrierInfo">
                <v-list-item-content>
                    <v-list-item-title>Carrier (Network)</v-list-item-title>
                    <v-list-item-subtitle>{{ carrierInfo.network }} ({{ carrierInfo.country }})</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>MCC / MNC</v-list-item-title>
                    <v-list-item-subtitle>{{ client.mcc }} / {{ client.mnc }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>KosherOS Version</v-list-item-title>
                    <v-list-item-subtitle>{{ client.latestBuildDate ? moment(client.latestBuildDate).format('LL') : ''
                        }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>Last Check-In</v-list-item-title>
                    <v-list-item-subtitle>{{ moment(client.lastCheckIn).calendar(null, {
                        sameElse: 'M-D-YYYY h:mm:ss a'
                    }) }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-card>
</template>

<script>
import moment from 'moment'
import * as momentDiff from 'moment-precise-range-plugin'
import PhoneNumber from 'awesome-phonenumber'

export default {
    name: 'ClientDeviceStatus',
    props: {
        client: {
            type: Object,
            required: true
        },
        devices: {
            type: Array,
            default: () => []
        },
        carriers: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            moment,
            PhoneNumber,
            MomentDiff: momentDiff
        }
    },
    computed: {
        deviceName() {
            const device = this.devices.find(d => d.codename === this.client.device)
            return device ? device.name : 'unknown'
        },
        carrierInfo() {
            return this.carriers.find(carrier =>
                carrier.mcc === this.client.mcc && carrier.mnc === this.client.mnc
            )
        }
    }
}
</script>