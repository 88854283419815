<template>
    <div>
        <v-card>
            <v-card-title>Send FCM Data Message</v-card-title>
            <v-card-text>
                <v-select v-model="selectedToken" :items="fcmTokenItems" label="Select FCM Token" item-text="label"
                    item-value="token" outlined></v-select>
                <v-select v-model="selectedProject" :items="projectIds" label="Select Project ID" outlined></v-select>
                <v-text-field v-model="dataKey" label="Data Key" outlined></v-text-field>
                <v-text-field v-model="dataValue" label="Data Value" outlined></v-text-field>
                <v-btn color="primary" @click="sendMessage" :disabled="!canSend">
                    Send FCM Data Message
                </v-btn>
            </v-card-text>
        </v-card>
        <v-card class="mt-4" v-if="messageResult">
            <v-card-title>FCM Message Result</v-card-title>
            <v-card-text>
                <pre>{{ JSON.stringify(messageResult, null, 2) }}</pre>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { clientApi } from '../services/api'

export default {
    name: 'ClientFcmMessage',

    props: {
        fcmTokens: {
            type: Array,
            default: () => []
        },
        projectIds: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            selectedToken: null,
            selectedProject: null,
            dataKey: '',
            dataValue: '',
            messageResult: null
        }
    },

    computed: {
        fcmTokenItems() {
            return this.fcmTokens.map(token => ({
                label: `${token.app} - ${token.token.substr(0, 20)}...`,
                token: token.token
            }))
        },

        canSend() {
            return this.selectedToken &&
                this.selectedProject &&
                this.dataKey &&
                this.dataValue !== undefined
        }
    },

    methods: {
        async sendMessage() {
            try {
                const response = await clientApi.sendFcmMessage({
                    token: this.selectedToken,
                    projectId: this.selectedProject,
                    dataKey: this.dataKey,
                    dataValue: this.dataValue
                })
                this.messageResult = response.data
                this.$emit('message-sent', response.data)
            } catch (error) {
                console.error('Error sending FCM message:', error)
                this.messageResult = {
                    success: false,
                    error: error.response?.data?.error || error.message
                }
                this.$emit('message-error', error)
            }
        }
    }
}
</script>